.loadingScreen {
    width:256px;
    height:400px;
    margin: auto;
    position: absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    text-align:center;
}
