.lobbyScreen {
    max-width: 440px;
    margin: 20px auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    overflow-x: auto;
    overflow-y: hidden;
}

.lobbyScreen > h1 {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 128px;
}
.lobbyScreen > h1 > img {
    width: 128px
}

.lobbyScreen > h1 > span {
    display: inline-block
}

.lobbyScreen button {
    margin: 20px auto 10px auto;
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0px;
}

.lobbyScreen .participants {
    position: absolute;
    top: 128px;
    bottom: 85px;
    align-content: center;
}