
.participants
{
    list-style-type: none;
    padding: 0 10px ;
    display:flex;
    gap:10px;
    justify-content: center;
    flex-wrap: wrap;
}
